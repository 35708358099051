<template>
	<router-view></router-view>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			return_url: null
		}
	},
	created() {
		// if(this.$route.query.return_url !== undefined) {
		// 	this.return_url = this.$route.query.return_url;
		// }
		// // alert('正在跳转，请稍后！');
		// // window.history.go(-1)
		// if(this.return_url) {
		// 	// alert('正在跳转，请稍后！');
		// 	setTimeout(() => {
		// 		window.location.href = this.return_url;
		// 	}, 1000)
		// }
	},

	mounted() {
		console.log('进入cashier页面');
	}
};
</script>
